section#main-page-container {
  padding-bottom: 15px;

  & #content-container {
    margin-bottom: 45px;
  }

  & #top-spacing-container {
    margin-top: -0.5rem;
  }
  & h2.mt-5 {
    font-weight: 500;
  }

  & .projects-container {
    margin-bottom: 50px;
  }
}

.contact-items p {
  margin: 0;
}

#about-container {
  #photo-container {
    margin-top: 20px;
    img {
      border-radius: 5px;
      width: 300px;
    }
  }
  & #bio-container {
    & #bio-p2-container {
      & p a {
        color: black;
        &:hover {
          color: #087891;
        }
      }
      & a.docs-link {
        cursor: pointer;
        & span.lang-name {
          color: #087891;
          &:hover {
            color: #005569;
          }
        }
      }
    }
  }

  & #resume-container {
    margin: 20px 0 20px;
    & h3 {
      color: #0d6efd;
      width: max-content;
      cursor: pointer;
      &:hover {
        color: #0a58ca;
      }
    }
  }
}

.project-items {
  margin: 30px 0;
}

.project-item {
  padding: 10px 0;
}

a {
  text-decoration: none;
}

div.social-links {
  display: flex;
  gap: 15px;
  margin: 6px 0 15px;
}

div.social-links img {
  width: 25px;
  margin-right: 3px;
}

.project-links {
  padding-top: 3px;
  margin-bottom: 15px;
  display: flex;
}

.project-links p {
  padding: 0 10px;
  margin: 0;
}

.container-card {
  margin: 0px 0;
}
